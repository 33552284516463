<template>
  <div>
    <el-card shadow="never" class="border-none">
      <el-row>
        <el-col :span="8">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="待审核" border @change="handleCheckboxChanged"></el-checkbox>
            <el-checkbox label="已审核" border @change="handleCheckboxChanged"></el-checkbox>
            <el-checkbox label="已删除" border @change="handleCheckboxChanged"></el-checkbox>
          </el-checkbox-group>
        </el-col>
        <el-col :span="16">
          <el-button type="primary" @click="dataBinding()">刷新</el-button>
          <el-button type="success" v-if="form.state==0" @click="handlePassModel()">通过</el-button>
          <el-button type="info" @click="nextCommunityInfo()">下一条</el-button>
        </el-col>
      </el-row>
    </el-card>
    <div class="main-conent main-conent-minheight" v-if="form" v-loading="loading">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="first">

          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="状态">
              <el-tag :type="form.state===0 ? 'primary' : 'info'" disable-transitions>
                {{form.state===0 ?'待审核':'已审核'}}
              </el-tag>
              <el-tag v-if="form.state==99" type='danger' disable-transitions>
                已删除
              </el-tag>
            </el-form-item>
            <el-form-item label="发布作者">
              <el-input v-model="form.appUserName"></el-input>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="交付时间">
              <el-input v-model="form.finishDate"></el-input>
            </el-form-item>
            <el-form-item label="交付地点">
              <el-input v-model="form.finishArea"></el-input>
            </el-form-item>
            <el-form-item label="参与人数">
              <el-input v-model="form.peopleCount"></el-input>
            </el-form-item>
            <el-form-item label="项目亮点">
              <el-input type="textarea" v-model="form.bright" :rows="8"></el-input>
            </el-form-item>
            <el-form-item label="背景概述">
              <el-input type="textarea" v-model="form.summary" :rows="8"></el-input>
            </el-form-item>
            <el-form-item label="图集">
              <el-image v-for="(img , indexx) in form.imgs" :key="indexx" style="width: 100px; height: 100px" :src="img"
                :preview-src-list="form.imgs">
              </el-image>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="圈子评论" name="second">
          <el-timeline :reverse="false" style="margin-top:20px;">
            <el-timeline-item v-for="(comment, index) in comments" :key="index" :timestamp="comment.createTime"
              placement="top">
              <el-card>
                <div style="display:flex;flex-flow: row nowrap;align-items: center;">
                  <el-avatar size="small" :src="comment.avatar"></el-avatar>
                  <span style="margin-left: 10px;">{{comment.name}}</span>
                </div>
                <p>{{comment.content}}</p>
                <div v-if="comment.replyCount>0">
                  <el-divider content-position="left">作者回复</el-divider>
                  <div style="display:flex;flex-flow: row nowrap;align-items: center;">
                    <el-avatar size="small" :src="comment.reply.avatar"></el-avatar>
                    <span style="margin-left: 10px;">{{comment.reply.name}}</span>
                  </div>
                  <p>{{comment.reply.content}}</p>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getList, updateState, updateRemark, getInfo, getNextInfo } from '@/service/community.js';
export default {
  data() {
    return {
      loading: false,
      activeName: 'first',
      communityId: 0,
      checkList: ['待审核'],
      form: {},
      comments: [],
      imgs: []
    };
  },
  computed: {
    FullImgUrl() {
      return function (img) {
        let fullImg = process.env.VUE_APP_HOST + img;
        return fullImg;
      };
    }
  },
  mounted() {
    if (this.$route.query.communityId) {
      this.communityId = this.$route.query.communityId;
      this.dataBinding();
    }
  },
  methods: {
    //复选框变更
    handleCheckboxChanged(e) {
      this.dataBinding();
    },
    //数据加载绑定
    dataBinding() {
      this.loading = true;
      getInfo(this.communityId).then(res => {
        this.form = res.info;
        this.comments = res.comments;
        this.imgs = res.info.imgs;
        this.loading = false;
      });
    },
    //获取下一条圈子信息
    nextCommunityInfo() {
      let stateArray = [];
      this.checkList.forEach(item => {
        if (item === '待审核')
          stateArray.push(0);
        else if (item === '已审核')
          stateArray.push(1);
        else if (item === '已删除')
          stateArray.push(99);
      });
      const states = stateArray.join(',');
      const request = { currentId: this.communityId, states: states };
      this.loading = true;
      getNextInfo(request).then(res => {
        console.log(res.info);
        this.form = res.info;
        this.communityId = res.info.id;
        this.comments = res.comments;
        this.imgs = res.info.imgs;
        this.loading = false;
      });
    },
    //通过审核
    handlePassModel() {
      this.loading = true;
      let req = { id: this.form.id, state: 1 };
      updateState(req).then(res => {
        if (res === true) {
          this.$message("审核操作成功");
          this.nextCommunityInfo();
        }
        else {
          this.$message("操作失败");
        }
        this.loading = false;
      });
    }
  }
};
</script>
