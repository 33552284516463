
import request from '@/service/lib/request';


export function getList (data) {
    return request({
      url: '/community/communities',
      method: 'get',
      params: data
    });
  }

  export function updateState (data) {
    return request({
      url: '/community/state',
      method: 'post',
      data
    });
  }

  export function updateRemark (data) {
    return request({
      url: '/community/remark',
      method: 'post',
       data
    });
  }

  
  export function getInfo (id) {
    return request({
      url: '/community/community_info/'+id,
      method: 'get'
    });
  }

  export function getNextInfo (data) {
    return request({
      url: '/community/community_nextinfo',
      method: 'post',
      data
    });
  }
  